<template>
  <router-link :to="`${$locale.base}${link}`"
               class="event-card grid-sm-4">
    <div class="event-card__wrapper">
      <Column>
        <div class="event-card__image-holder">
          <div class="event-card__image-link">
            <img :src="image"
                 class="event-card__image"
                 alt=""/>
          </div>
          <Favorite class="event-card__favorites"
                    :params="{id, entity, favorited}"/>
        </div>
        <div class="event-card__info-holder">
          <div class="event-card__info-type">{{$type(type)}}</div>
          <div class="event-card__info-title">{{title}}</div>
          <Row class="event-card__info-icon-date">
            <Icon class="event-card__info-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            <span class="event-card__info-date">{{date}}</span>
          </Row>
        </div>
      </Column>
    </div>
  </router-link>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'EventCard',
  data() {
    return {
      formatDate: 'DD MMMM, HH:mm',
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    type() {
      return this?.params?.type;
    },
    title() {
      return this?.params?.title;
    },
    date() {
      return this.$moment.unix(this.params.started_at)
        .format(this.formatDate)
          || this.$moment.unix(this.params.created_at)
            .format(this.formatDate);
    },
    link() {
      return `/${this?.params?.entity}/${this?.params?.id}`;
    },
    image() {
      if (this?.params?.images?.length > 0) return this.params.images[0];
      return errorImage;
    },
  },
};
</script>
